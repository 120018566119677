import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';



import { GoogleMapScript } from './components/googleMapScript';
import HomePage from './views/homePage';
import LoginPage from './views/loginPage';
import ListPage from './views/listPage';
import FormPage from './views/formPage';
import Order from './views/order';
//const history = createHistory();


let formPages = [
    {
        title: 'Kupon',
        subtitle: 'Izmjena kupona',
        link: '/coupons',
        collection: 'coupons',
        fields: [

            {
                name: 'code',
                label: 'Kod',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'discount',
                label: 'Popust [KM]',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },

            {
                name: 'used',
                label: 'Iskorišten',
                type: 'checkbox',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },

    {
        title: 'Slide',
        subtitle: 'Izmjena slajda',
        link: '/slides',
        collection: 'slides',
        fields: [

            {
                name: 'DesktopImage',
                label: 'Desktop slika',
                type: 'file',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'TabletImage',
                label: 'Tablet slika',
                type: 'file',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'PhoneImage',
                label: 'Mobile slika',
                type: 'file',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'title',
                label: 'Naslov',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'subtitle',
                label: 'Podnaslov',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'description',
                label: 'Opis',
                type: 'text-multiline',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'link',
                label: 'Link',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'position',
                label: 'Position',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
    {
        title: 'Banner',
        subtitle: 'Izmjena banera',
        link: '/banners',
        haveImage: true,
        collection: 'banners',
        fields: [

            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },
            {
                name: 'title',
                label: 'Naslov',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'subtitle',
                label: 'Podnaslov',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'link',
                label: 'Link',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
    {
        title: 'Newsletter',
        subtitle: 'Configure newsletter',
        link: '/newsletters',
        collection: 'newsletters',
        multilang: false,
        fields: [
            {
                name: 'Title',
                label: 'Title',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }, {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }, {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Text',
                label: 'Text',
                type: 'html',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'list',
                name: 'products',
                width: {
                    desktop: 4,
                    mobile: 12
                },

                fields: [
                    {
                        type: 'image',
                        name: 'Image',
                        width: {
                            desktop: 2,
                            mobile: 2
                        }
                    },
                    {
                        type: 'text',
                        name: 'Alias',
                        width: {
                            desktop: 4,
                            mobile: 4
                        }
                    },
                    {
                        type: 'text',
                        name: 'Name.de',
                        width: {
                            desktop: 4,
                            mobile: 4
                        }
                    },
                ],

            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                id: '342yh43hi234',
                type: 'search',
                label: 'Search products',
                collection: 'products',
                searchFields: ['Alias', 'Name.de'],
                fields: [
                    {
                        type: 'image',
                        name: 'Image'
                    },
                    {
                        type: 'text',
                        name: 'Alias'
                    },
                    {
                        type: 'text',
                        name: 'Name.de'
                    }
                ],
                limit: 10,
                onSelect: (scope, value) => {
                    console.log(value);
                    let fields = scope.state.fields;
                    let initialValues = scope.state.initialValues;
                    console.log(initialValues['products']);
                    if (!initialValues['products'])
                        initialValues['products'] = [];
                    initialValues['products'].push(value);
                    for (let i = 0; i < fields.length; i++) {
                        fields[i]._temp = new Date();

                    }
                    scope.setState({ initialValues: initialValues, fields: [] }, () => {
                        scope.setState({ fields: fields }, () => {
                            scope.forceUpdate();

                        });
                    });


                },
                width: {
                    desktop: 4,
                    mobile: 12
                }
            }, {
                type: 'spacer'
            },
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Narudžba',
        subtitle: 'Pregled narudžbe',
        link: '/orders',
        collection: 'orders',
        multilang: false,
        fields: [
            {
                type: 'label',
                name: 'orderNumber',
                beforeText: 'Broj narudžbe: ',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            }, {
                type: 'label',
                name: 'Status',
                beforeText: 'Status: ',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'label',
                name: 'DeliveryMethod.name',
                beforeText: 'Dostava: ',

                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'BillingMethod',
                beforeText: 'Način plaćanja: ',
                type: 'label',

                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'label',
                name: 'subtotal',
                beforeText: 'Iznos bez PDV-a: ',
                afterText: ' KM',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'label',
                name: 'tax',
                beforeText: 'Iznos PDV-a ',
                afterText: ' KM',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'label',
                name: 'total',
                beforeText: 'Iznos sa PDV: ',
                afterText: ' KM',
                width: {
                    desktop: 4,
                    mobile: 12
                }

            },
            {
                type: 'label',
                name: 'Coupon',
                beforeText: 'Kupon: ',
                afterText: '',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            }, {
                type: 'label',
                name: 'Note',
                beforeText: 'Napomena: ',
                afterText: '',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },


            {
                type: 'spacer'
            },


            {
                type: 'title',
                text: 'Adresa za obračun'
            },

            {
                name: 'BillingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Street',
                label: 'Ulica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Company',
                label: 'Firma',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },



            {
                type: 'title',
                text: 'Adresa za isporuku'
            },

            {
                name: 'ShippingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Street',
                label: 'Ulica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'ShippingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },

            {
                type: 'button',
                text: 'Prikaži narudžbenicu',
                width: {
                    desktop: 3,
                    mobile: 12
                },
                onClick: (scope) => {
                    window.location = 'https://wunderland-admin.novamedia.agency/order/' + scope.state.initialValues._id;
                }

            },
            {
                type: 'spacer'
            },
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Proizvod',
        subtitle: 'Dodaj/Izmjeni proizvod',
        link: '/products',
        collection: 'products',
        fields: [
            {
                name: 'Alias',
                label: 'Šifra proizvoda',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BarCode',
                label: 'Bar kod',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Proizvod je vidljiv u shopu',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'IsPopular',
                label: 'Popularan proizvod',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                "type": "spacer"
            },
            {
                name: 'Images',
                label: 'Slike',
                type: 'file-multiple',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            }, {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Name',
                label: 'Naziv proizvoda',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                type: 'spacer',
            },
            {
                name: 'OrderUnit',
                label: 'Jedinica mjere',
                type: 'select',
                values: [
                    {
                        name: 'Komad',
                        value: 'komad'
                    },
                ],
                width: {
                    desktop: 2,
                    mobile: 12
                }
            }, {
                type: 'spacer',
            },
            {
                name: 'MinOrder',
                label: 'Minimalna narudzba (komada)',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'StockLevel',
                label: 'Na stanju',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'StockLevelAlert',
                label: 'Upozorenje za magacin (komada)',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },
            {
                name: 'Description',
                label: 'Opis artikla',
                type: 'html',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            }

        ],
        linkedFields: [
            {
                linkedCollection: 'categories',
                name: 'Breadcrumb',
                label: 'Category',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item.Breadcrumb
                            }
                        )
                    });
                },
                additionalAction: (scope, value) => {

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },

        ],



    },

    {
        title: 'Korisnik',
        subtitle: 'Dodaj/Izmjeni korisnika',
        link: '/users',
        collection: 'users',
        multilang: false,
        fields: [
            {
                name: 'User.Name',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'User.EMail',
                label: 'Email',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'CustomerGroup',
                label: 'Korisnička grupa',
                type: 'select',
                values: [{ name: 'Fizičko lice', value: 'PhysicalPerson' }, { name: 'Pravno lice', value: 'LegalPerson' }],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },



            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'EmailVerified',
                label: 'Verifikovan email',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'SubscribeToNewsletter',
                label: 'Prijavljen na newsletter',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },

            {
                type: 'title',
                text: 'Adresa za obračun'
            },

            {
                name: 'BillingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Street',
                label: 'Ulica i broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Company',
                label: 'Firma',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },

            {
                type: 'title',
                text: 'Adresa za isporuku'
            },

            {
                name: 'ShippingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Street',
                label: 'Ulica i broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            }, {
                name: 'ShippingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },

            {
                name: 'ShippingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },


        ],
        linkedFields: []

    }, {
        title: 'Kategorija',
        subtitle: 'Dodaj/Izmjeni kategoriju',
        link: '/categories',
        collection: 'categories',

        fields: [
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                generateAlias: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Name',
                label: 'Naziv',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Position',
                label: 'Pozicija',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Vidljiva',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

        ],
        linkedFields: [
            {
                linkedCollection: 'categories',
                name: 'ParentCategory',
                label: 'Kategorija',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item._id
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }

        ]

    },



    {
        title: 'Dostava',
        subtitle: 'Dodaj/Izmjeni dostavu',
        link: '/delivery-methods',
        collection: 'deliveryMethods',

        fields: [
            {
                name: 'alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'name',
                label: 'Ime',
                type: 'text',

                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'price',
                label: 'Cijena',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            }
        ],
        linkedFields: []
    },





]


let listPages = [
    {
        title: 'Kuponi',
        subtitle: 'Lista kupona',
        link: '/coupons',
        collection: 'coupons',
        searchFields: ['code'],
        itemFields: [
            {
                name: "code",
                label: "Kod",
                type: "text",
            },
            {
                name: 'discount',
                label: "Popust[KM]",

                type: "price",
                allowEdit: true
            },

        ],

    }, {
        title: 'Proizvodi',
        subtitle: 'Lista proizvoda',
        link: '/products',
        haveImage: true,
        collection: 'products',
        searchFields: ['Alias', 'Name'],
        itemFields: [
            {
                name: 'Images[0]',
                type: "image",
                disableSort: true
            }, {
                name: "Alias",
                label: "Šifra proizvoda",
                type: "text",
            },
            {
                name: 'Name',
                label: "Naziv",

                type: "text"
            },

            {
                name: "price",
                label: "Cijena",

                type: "price",
                allowEdit: true
            },


            {
                name: "discount",
                label: "Popust [%]",

                type: "number",
                allowEdit: true
            },

            {
                name: "StockLevel",
                label: "Stanje",

                type: "number",
                allowEdit: true

            }
        ],

    },
    {
        title: 'Korisnici',
        subtitle: 'Lista korisnika',
        link: '/users',
        haveImage: false,
        collection: 'users',
        searchFields: ['Alias', 'User.Name', 'User.EMail'],
        itemFields: [
            /*{
                name: '_id',
                type: "text"
            }*/,
            {
                name: 'User.Name',
                label: 'Name',
                type: "text"
            },
            {
                name: 'User.EMail',
                label: 'EMail',
                type: "text"
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: "text"
            },

            {
                name: 'CustomerGroup',
                label: 'Korisnička grupa',
                type: "text"
            },

        ],
    }, {
        title: 'Kategorije',
        subtitle: 'Lista kategorija',
        link: '/categories',
        collection: 'categories',
        searchFields: ['Alias', 'Name'],
        itemFields: [
            {
                name: 'Alias',
                type: "text",
                label: "Alias"
            },
            {
                name: 'Breadcrumb',
                type: "text",
                label: "Breadcrumb"

            },
        ],

    }, {
        title: 'Dostave',
        subtitle: 'Lista dostava',
        link: '/delivery-methods',
        haveImage: false,
        collection: 'deliveryMethods',
        searchFields: ['alias', 'name'],
        itemFields: [
            {
                name: 'alias',
                type: "text",
                label: "Alias"

            },
            {
                name: 'name',
                type: "text",
                label: "Name"

            },
            {
                name: 'price',
                type: "price",
                label: "Price"

            },
        ],
    },

    {
        title: 'Newsletters',
        subtitle: 'List of newsletters',
        link: '/newsletters',
        haveImage: true,
        collection: 'newsletters',
        searchFields: ['Title', 'Text'],
        itemFields: [
            {
                name: 'Title',
                type: "text"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },
    {
        title: 'Kontakti',
        subtitle: 'Lista kontakata',
        link: '/contacts',
        collection: 'contacts',
        disableDelete: true,
        disableEdit: true,
        searchFields: ['EMail', 'Name'],
        itemFields: [
            {
                name: 'Name',
                label: 'Ime',
                type: "text"
            },
            {
                name: 'EMail',
                label: 'EMail',

                type: "text"
            }, {
                name: 'Telephone',
                label: 'Telefon',

                type: "text"
            }, {
                name: 'Subject',
                label: 'Naslov',

                type: "text"
            }, {
                name: 'Message',
                label: 'Poruka',

                type: "text"
            },

        ],
    },
    {
        title: 'Slajdovi',
        subtitle: 'Lista slajdova',
        link: '/slides',
        haveImage: true,
        collection: 'slides',
        searchFields: ['alias', 'name'],
        itemFields: [
            {
                name: 'DesktopImage',
                type: "image"
            }, {
                name: 'title',
                type: "text",
                label: 'Naslov'
            },
            {
                name: 'subtitle',
                type: "text",
                label: 'Podnaslov'

            },
        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },
    {
        title: 'Baneri',
        subtitle: 'Lista banera',
        link: '/banners',
        haveImage: true,
        collection: 'banners',
        searchFields: ['title', 'subtitle'],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            }, {
                name: 'title',
                type: "text",
                label: 'Naslov'

            },
            {
                name: 'subtitle',
                type: "text",
                label: 'Podnaslov'

            },
        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },
    {
        title: 'Narudzbe',
        subtitle: 'Lista narudzbi',
        link: '/orders',
        collection: 'orders',
        disableDelete: true,
        searchFields: ['PayPalTransaction.transactions.invoice_number', 'BillingAddress.FirstName', 'BillingAddress.LastName', 'BillingAddress.Company'],
        itemFields: [
            {
                name: 'orderNumber',
                label: 'Broj',
                type: "text"
            },
            {
                name: 'ShippingAddress.FirstName',
                label: 'Ime',
                type: "text"
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'Prezime',

                type: "text"
            },
            {
                name: 'ShippingAddress.EMail',
                label: 'EMail',

                type: "text"
            },
            {
                name: 'ShippingAddress.City',
                label: 'Grad',

                type: "text"
            },
            {
                name: 'DeliveryMethod.name',
                label: 'Dostava',

                type: "text"
            },
            {
                name: 'BillingMethod',
                label: 'Način plaćanja',

                type: "text"
            },

            {
                name: 'total',
                label: 'Ukupno',
                type: "price"
            },
            {
                name: 'Coupon',
                label: 'Kupon',

                type: "text"
            },

            {
                name: 'Note',
                label: 'Napomena',

                type: "text"
            },

            {
                name: 'Status',
                label: 'Status',
                type: "text"
            },

            {
                name: 'orderTime',
                label: 'Datum',

                type: "datetime",
                format: "DD.MM.YYYY  HH:mm"
            },
        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    }


];


class Routes extends Component {

    componentDidMount() {
        /*const unlisten = history.listen((location, action) => {
            this.props.handleMenu(null);
        });
        */
    }

    render() {
        return (
            <Router >
                <div>

                    <GoogleMapScript API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />

                    <Switch className="react-switch">
                        <Route
                            path="/"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/login"
                            exact
                            render={(...renderProps) => (
                                <LoginPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/order/:id"
                            exact
                            render={(...renderProps) => (
                                <Order {...renderProps} {...this.props} />
                            )}
                        />

                        {
                            listPages.map((page, idx) => {
                                return (
                                    <Route
                                        path={page.link}
                                        exact
                                        render={(...renderProps) => (
                                            <ListPage {...page} {...renderProps} {...this.props} />
                                        )}
                                    />
                                )

                            })

                        }

                        {
                            formPages.map((page, idx) => {
                                return (
                                    <Route
                                        path={`${page.link}/:id`}
                                        exact
                                        render={(...renderProps) => (
                                            <FormPage {...page} {...renderProps} {...this.props} />
                                        )}
                                    />
                                )

                            })
                        }


                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;