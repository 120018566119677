import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import ebay_icon from '../assets/images/ebay.png';
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animate: true,
            animateSidebar: true
        };

    }

    componentDidUpdate(prevProps) {

    }

    render() {

        return (
            <div className={this.props.sidebar ? this.state.animate ? "sidebar animate-sidebar" : "sidebar" : 'sidebar sidebar-hide'} >
                <ul>


                    <li>
                        <Link to='/'>
                            <i className="mdi mdi-home"></i>
                            <span>{this.props.translate('Početna')}</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/orders'>
                            <i className="mdi  mdi-package-variant-closed"></i>
                            <span>{this.props.translate('Narudzbe')}</span>
                        </Link>

                    </li>


                    <li>
                        <a onClick={() => { this.props.openSubMenu('users') }}>
                            <i className="mdi  mdi-account"></i>
                            <span>{this.props.translate('Korisnici')}</span>
                        </a>

                        {
                            this.props.subMenu === 'users' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/users/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Dodaj korisnika')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/users'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('Lista korisnika')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }
                    </li>




                    <li>
                        <a onClick={() => { this.props.openSubMenu('products') }}>
                            <i className="mdi  mdi-package-variant"></i>
                            <span>{this.props.translate('Proizvodi')}</span>
                        </a>

                        {
                            this.props.subMenu === 'products' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/products/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Dodaj proizvod')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/products'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('Lista proizvoda')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }
                    </li>


                    <li>
                        <a onClick={() => { this.props.openSubMenu('coupons') }}>
                            <i className="mdi  mdi-tag-text-outline"></i>
                            <span>{this.props.translate('Kuponi')}</span>
                        </a>

                        {
                            this.props.subMenu === 'coupons' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/coupons/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Dodaj kupon')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/coupons'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('Lista kupona')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }
                    </li>
                    
             
               

                    <li>

                        <a onClick={() => { this.props.openSubMenu('categories') }}>
                            <i className="mdi  mdi-folder"></i>
                            <span>{this.props.translate('Kategorije')}</span>
                        </a>

                        {
                            this.props.subMenu === 'categories' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/categories/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Dodaj kategoriju')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/categories'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('Lista kategorija')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }
                    </li>



                    <li>

                        <a onClick={() => { this.props.openSubMenu('deliveryMethods') }}>
                            <i className="mdi  mdi-truck"></i>
                            <span>{this.props.translate('Dostava')}</span>
                        </a>

                        {
                            this.props.subMenu === 'deliveryMethods' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/delivery-methods/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Dodaj način dostave')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/delivery-methods'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('Lista dostava')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }
                    </li>

                    <li>
                        <a onClick={() => { this.props.openSubMenu('gallery') }}>
                            <i className="mdi mdi-file-image"></i>
                            <span>{this.props.translate('Slider na početnoj')}</span>
                        </a>

                        {
                            this.props.subMenu === 'gallery' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/slides/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Dodaj slajd')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/slides'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('Lista slajdova')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }


                    </li>

 <li>
                        <a onClick={() => { this.props.openSubMenu('banners') }}>
                            <i className="mdi mdi-image-area"></i>
                            <span>{this.props.translate('Baneri')}</span>
                        </a>

                        {
                            this.props.subMenu === 'banners' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/banners/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Dodaj baner')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/banners'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('Lista banera')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }


                    </li>

                    <li>

                        <a onClick={() => { this.props.openSubMenu('newsletter') }}>
                            <i className="mdi  mdi-email-outline"></i>
                            <span>{this.props.translate('Newsletter')}</span>
                        </a>

                        {
                            this.props.subMenu === 'newsletter' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/newsletters/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Add newsletter')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/newsletters'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('List of newsletters')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }
                    </li>
                    <li>
                        <Link to='/contacts'>
                            <i className="mdi  mdi-email-outline"></i>
                            <span>{this.props.translate('Kontakti')}</span>
                        </Link>
</li>


                    <li>
                        <a onClick={() => this.props.setUserData(null)}>
                            <i className="mdi mdi-power"></i>
                            <span>{this.props.translate('Odjava')}</span>
                        </a>
                    </li>




                </ul>

            </div>
        )
    }

};

export default Sidebar;